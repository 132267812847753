.gridContainer {
    display: flex;
    justify-content: center;
}

.gifGridContainer {
    display: grid;
    grid-template-columns: 1fr;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .gifGridContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 86vw;
    }
} 


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .gifGridContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 86vw;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .gifGridContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 86vw;
    }
}