.gifGridItem {
    margin-bottom: 45px;
    height: 270px;
    width: 316px;
}

.gif_container {
    display: flex;
    justify-content: center;
}

.gif {
    width: 316px;
}

.gif_title {
    font-size: 18px;
    text-align: left;
    margin-bottom: 1px;
}

.tag_container {
    margin-bottom: 10px;
}

.tag {
    font-size: 11px;
    text-align: left;
    padding-right: 10px;
}

/* Small devices (landscape, 650px and up) */
@media only screen and (min-width: 650px) {
    .gifGridItem {
        height: auto;
        width: 480px;
    }
    .gif {
        width: 480px;
    } 
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .gifGridItem {
        width: 40vw;
    }
    .gif {
        max-width: 40vw;
    }
    .titlentag_container {
        height: 78px;
    }
} 


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .gifGridItem {
        width: 26vw;
    }
    .gif {
        max-width: 26vw;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}